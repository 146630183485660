import React, { Suspense } from "react";
import ThemeContextProvider from "./contexts/theme";
import { AuthContextProvider } from "./contexts/auth";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import {
  LoginPage,
  ListUserProfilesPage,
  AppLoading,
  AppCheckingAdmin,
  ListTestResultsPage,
  EditUserProfilePage,
  EditTestResultPage,
} from "./pages";
import { Path } from "./paths";
import { LocaleContextProvider } from "./contexts/locale";
import "./App.scss";

export const App = (): React.ReactElement => {
  return (
    <LocaleContextProvider>
      <ThemeContextProvider>
        <AuthContextProvider>
          <main className="app-container">
            <Suspense fallback={<AppLoading />}>
              <BrowserRouter>
                <Routes>
                  <Route path={Path.user} element={<ProtectedRoute />}>
                    <Route
                      path=":userId/:profileId"
                      element={<EditUserProfilePage />}
                    />
                    <Route path="" element={<ListUserProfilesPage />} />
                  </Route>
                  <Route path={Path.testResult} element={<ProtectedRoute />}>
                    <Route
                      path=":userId/:testId"
                      element={<EditTestResultPage />}
                    />
                    <Route path="" element={<ListTestResultsPage />} />
                  </Route>
                  <Route
                    path={Path.checkingAdmin}
                    element={<AppCheckingAdmin />}
                  />
                  <Route path={Path.login} element={<LoginPage />} />
                  <Route
                    path={Path.root}
                    element={<Navigate to={Path.user} />}
                  />
                  <Route path="*" element={<p>404 Not Found</p>} />
                </Routes>
              </BrowserRouter>
            </Suspense>
          </main>
        </AuthContextProvider>
      </ThemeContextProvider>
    </LocaleContextProvider>
  );
};

export default App;
