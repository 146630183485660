import React from "react";
import { LocaleProvider } from "@oursky/react-messageformat";
import enJson from "../locales/en.json";
import zhJson from "../locales/zh.json";

const messagesByLocale: Record<string, Record<string, string>> = {
  en: enJson,
  zh: zhJson,
};

interface Props {
  children: React.ReactNode;
}

export const LocaleContextProvider: React.FC<Props> = ({ children }) => {
  // TODO: switch locale logic
  const locale = "en";
  const messageByID = messagesByLocale[locale];
  return (
    <LocaleProvider locale={locale} messageByID={messageByID}>
      {children}
    </LocaleProvider>
  );
};
