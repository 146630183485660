import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

interface Props {
  children: React.ReactNode;
}

const ThemeContextProvider: React.FC<Props> = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default ThemeContextProvider;
