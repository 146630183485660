import React from "react";

export const LoginPage = React.lazy(async () => import("./LoginPage"));
export const ListUserProfilesPage = React.lazy(
  async () => import("./ListUserProfilesPage")
);
export const EditUserProfilePage = React.lazy(
  async () => import("./EditUserProfilePage")
);
export const ListTestResultsPage = React.lazy(
  async () => import("./ListTestResultsPage")
);
export const EditTestResultPage = React.lazy(
  async () => import("./EditTestResultPage")
);
export const AppCheckingAdmin = React.lazy(
  async () => import("./AppCheckingAdmin")
);
export const AppLoading = React.lazy(async () => import("./AppLoading"));
