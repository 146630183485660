import React, { useContext } from "react";

import { AuthContext } from "./contexts/auth";

import { Outlet, Navigate } from "react-router-dom";

import { Path } from "./paths";

const ProtectedRoute: React.FC = () => {
  const authContext = useContext(AuthContext);

  if (!authContext) {
    return null;
  }

  if (authContext.user === null) {
    return <Navigate to={Path.login} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
