import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  UserCredential,
  User as FirebaseUser,
} from "firebase/auth";
import AppLoading from "../pages/AppLoading";
import { auth } from "../firebaseApp";

export interface IAuthContext {
  user: FirebaseUser | null;
  login: (email: string, password: string) => Promise<UserCredential>;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<IAuthContext | undefined>(
  undefined
);

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<FirebaseUser | null | undefined>(undefined);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return () => unsubscribe();
  }, []);

  const login = useCallback(
    async (email: string, password: string): Promise<UserCredential> => {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      return userCredential;
    },
    []
  );

  const logout = useCallback(async (): Promise<void> => {
    setUser(null);
    await signOut(auth);
  }, []);

  const contextValue: IAuthContext | undefined = useMemo(() => {
    if (user === undefined) {
      return undefined;
    }
    return { user, login, logout };
  }, [login, logout, user]);

  if (contextValue === undefined) return <AppLoading />;

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
