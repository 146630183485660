import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

import { getFunctions } from "firebase/functions";

const firebaseApp = initializeApp({
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
});

export const auth = getAuth(firebaseApp);

export const functions = getFunctions(firebaseApp);
